<template>
    <el-drawer
        title="巡演管理"
        :visible.sync="drawerShow"
        :wrapperClosable="false"
        :destroy-on-close="true"
        append-to-body
        size="auto"
        @close="close"
    >
        <div class="drawer-content">
            <el-alert type="warning" title="如需删除整个巡演，请联系秀动官方客服" :closable="false" show-icon></el-alert>
            <el-form :model="formData" ref="formData" size="medium" label-position="top" class="MT20">
                <el-form-item prop="tourId" label="巡演ID:">
                    <span slot="label">巡演ID: {{formData.tourId}}</span>
                </el-form-item>
                <el-form-item prop="tourName" label="巡演名称:" :rules="[{required: true, message: '请输入巡演名称'}]">
                    <el-input v-model="formData.tourName" placeholder="请输入巡演名称" maxlength="100"></el-input>
                </el-form-item>
                <el-form-item label="绑定演出:">
                    <el-checkbox-group v-model="unbindActivityIds">
                        <div class="page-list-box">
                            <el-table
                                :data="dataList"
                                style="width: 100%"
                                v-loading="listLoading"
                                size="small"
                                header-row-class-name="page-list-table"
                            >
                                <el-table-column label="操作">
                                    <template slot-scope="scope">
                                        <!-- <el-button size="mini" type="text" @click="unboundTour(scope.row.id)">解绑</el-button> -->
                                        <el-checkbox :label="scope.row.id">解绑</el-checkbox>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="cityName" label="城市"></el-table-column>
                                <el-table-column prop="id" label="演出ID"></el-table-column>
                                <el-table-column prop="name" label="演出名称" min-width="200" show-overflow-tooltip></el-table-column>
                                <el-table-column prop="siteName" label="场地" min-width="100" show-overflow-tooltip></el-table-column>
                                <el-table-column prop="beginTime" label="首场开演时间" min-width="150"></el-table-column>
                            </el-table>
                        </div>
                    </el-checkbox-group>
                    <div v-if="data" class="page-pagination-box">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="getDataList"
                            :current-page.sync="params.pageNo"
                            :page-sizes="[15, 30, 50]"
                            :page-size.sync="params.pageSize"
                            layout="sizes, prev, pager, next"
                            :total="data.totalCount || 0">
                        </el-pagination>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <div class="drawer-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" :loading="btnLoading" @click="onSubmit">确定</el-button>
        </div>
    </el-drawer>
</template>
<script>
const defaultFormData = {
    tourName: "",
    tourId: "",
    unbindActivityIds: ""
}
const defaultParams = {
    pageNo: 1,
    pageSize: 15,
    searchType: 3,
    searchValue: "",
    sellType: 0,
    progressType: 0,
    priorityType: 1,
    cityType: 1,
    cityValue: "",
}
export default {
    data(){
        return{
            drawerShow: false,
            formData:{
                ...defaultFormData
            },
            data: null,
            dataList: [],
            params:{
                ...defaultParams
            },
            listLoading: false,
            btnLoading: false,
            unbindActivityIds: []
        }
    },
    methods:{
        show(data){
            this.formData = {
                ...defaultFormData,
                tourName: data.name,
                tourId: data.id
            }
            this.params = {
                ...defaultParams,
                searchValue: data.id
            }
            this.getDataList();
            this.drawerShow = true
        },
        close(){
            this.formData = { ...defaultFormData };
            this.$refs.formData && this.$refs.formData.resetFields();

            this.data = null;
            this.dataList = [];
            this.params = {...defaultParams};
            this.unbindActivityIds = [];

            this.drawerShow = false
        },
        //获取演出列表
        getDataList() {
            this.listLoading = true;
            this.$request({
                url: "/activity/list",
                method: "POST",
                data: this.params,
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.data = result;
                        this.dataList = result.result;
                    } else {
                        this.$message.error(msg || "请求失败");
                    }
                })
                .catch(() => {
                    this.$message.error("请求错误");
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        //选择pageSize
        handleSizeChange(e) {
            this.params.pageNo = 1;
            this.params.pageSize = e;
            this.getDataList();
        },

        //更新巡演
        onSubmit(){
            this.$refs["formData"].validate((valid) => {
                if (valid) {
                    this.btnLoading = true;

                    this.$request({
                        url: '/tour/updateTour',
                        data: {
                            ...this.formData,
                            unbindActivityIds: this.unbindActivityIds.toString()
                        },
                        method: "post",
                    })
                        .then((res) => {
                            const { state, msg } = res.data;
                            if (state == 1) {
                                this.$emit('onUpdate');
                                this.close();
                            } else {
                                this.$message.error(msg || "操作失败,请重试");
                            }
                        })
                        .catch(() => {
                            this.$message.error("操作失败,请重试");
                        })
                        .finally(() => {
                            this.btnLoading = false;
                        });
                }else{
                    return false;
                }
            })   
        },
        //解绑
        // unboundTour(id){
        //     this.listLoading = true;
        //     this.$request({
        //         url: '/tour/unboundTour',
        //         params: {activityId: id},
        //         method: "post",
        //     })
        //         .then((res) => {
        //             const { state, msg } = res.data;
        //             if (state == 1) {
        //                 this.$emit('onUpdate');
        //                 setTimeout(() => {
        //                     this.params.pageNo = 1;
        //                     this.getDataList();
        //                 },1500)
        //             } else {
        //                 this.$message.error(msg || "操作失败,请重试");
        //             }
        //         })
        //         .catch(() => {
        //             this.$message.error("操作失败,请重试");
        //         })
        //         .finally(() => {
        //              setTimeout(() => {
        //                  this.listLoading = false;
        //              },1500)
        //         });
        // }
    }
}
</script>
<style lang="scss" scoped>
.page-list-box{
    margin-top: -20px;
}
</style>