<template>
    <div>
        <div class="page-content-box">
            <div class="page-list-box">
                <el-table
                    :data="dataList"
                    style="width: 100%"
                    v-loading="listLoading"
                    size="small"
                    header-row-class-name="page-list-table"
                >
                    <el-table-column prop="id" label="ID"></el-table-column>
                    <el-table-column prop="name" label="名称" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="activityNum" label="绑定演出数"></el-table-column>
                    <el-table-column prop="createTime" label="创建时间"></el-table-column>
                    <el-table-column fixed="right" label="操作" width="130">
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                size="small"
                                @click="edit(scope.row)"
                                >管理</el-button
                            >
                            <el-button 
                                type="text"
                                size="small"
                                @click="viewActivity(scope.row.id)"
                                >查看对应演出</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div v-if="data" class="page-pagination-box">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="getDataList"
                    :current-page.sync="params.pageNo"
                    :page-sizes="[15, 30, 50]"
                    :page-size.sync="params.pageSize"
                    layout="sizes, prev, pager, next"
                    :total="data.totalCount || 0">
                </el-pagination>
            </div>
        </div>

        <tour-edit ref="tourEdit" @onUpdate="getDataList"></tour-edit>
    </div>
</template>

<script>
import TourEdit from './components/tour-edit';
export default {
    components:{TourEdit},
    data(){
        return{
            data: null,
            dataList: null,
            params:{
                pageNo:parseInt(this.$route.query.pageNo) || 1,
                pageSize: parseInt(this.$route.query.pageSize) || 15,
            },
            listLoading: true,
        }
    },
    created(){
        this.getDataList();
    },
    methods:{
        //获取列表
        getDataList() {
            this.listLoading = true;
            this.$request({
                url: "/tour/list",
                method: "POST",
                params: this.params,
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.data = result;
                        this.dataList = result.result;
                    } else {
                        this.$message.error(msg || "请求失败");
                    }
                })
                .catch(() => {
                    this.$message.error("请求错误");
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        //选择pageSize
        handleSizeChange(e) {
            this.params.pageNo = 1;
            this.params.pageSize = e;
            this.getDataList();
        },
        //搜索
        search() {
            this.params.pageNo = 1;
            this.getDataList();
        },
        //管理
        edit(data){
            this.$refs.tourEdit.show(data);
        },
        //查看对应演出
        viewActivity(id){
            this.$router.push(`/manage/activity/list?searchType=3&searchValue=${id}&sellType=0`);
        }
    }
}
</script>

